@use "theme" as *;

@forward "shared/variables/screen-sizes";
@forward "shared/variables/easing-functions";

$gap-s: 4px;
$gap-m: 8px;
$gap-l: 12px;
$gap-xl: 16px;

$border-radius-xs: 2px;
$border-radius-s: 4px;
$border-radius-m: 8px;
$border-radius-l: 12px;
$border-radius-xl: 16px;

$primary-hover-transition:
  border-color 0.3s ease,
  box-shadow 0.3s ease,
  background-color 0.3s ease;

$light-app-icon-max-lightness: 90;
$dark-app-icon-min-lightness: 16;

$app-icon-badge-background-max-lightness: 60;
/* stylelint-disable-next-line color-no-hex -- This color should not change with the theme */
$app-icon-badge-light-background-font-color: #24434f;

$drop-zone-background: $surface-inverse-opacity-5;

$highlight-color: $plum-fg;

$grid-gap: $gap-m;
$select-dropdown-margin: 4px;
$guest-container-default-max-width: 1280px;
$container-guest-padding-left: $grid-gap;
$container-guest-padding-right: $grid-gap;
$page-small-content-max-width: 744px;
$page-content-margin-bottom: 90px;
$page-aside-margin: 24px;
$page-aside-compact-margin: 16px;
$page-compact-view: 960px;

$header-z-index: 1000;
$header-inner-height: 40px;
$header-bar-height: 6px;
$header-bottom-margin: 8px;
$header-full-height: $header-inner-height + $header-bar-height + $header-bottom-margin;
$sidebar-collapsed-width: 48px;
$sidebar-collapsed-width-mobile: 8px;
$sidebar-expanded-width: 240px;
$sidebar-padding: 8px;
$sidebar-close-btn-size: 28px;
$nav-transition-duration: 200ms;
$nav-sidebar-shadow: 4px 0 20px 4px $neutral-dark-opacity-8;
$nav-icon-shadow: 0 4px 7px 0 $neutral-dark-opacity-20;
$nav-header-shadow: 0 4px 12px 0 $neutral-dark-opacity-8;
$nav-border: 1px solid $nav-border-opacity-30;

$app-layout-content-height: var(--app-layout-content-height);
$app-layout-content-width: var(--app-layout-content-width);
$app-layout-sticky-area-height: var(--app-layout-sticky-area-height);
$app-layout-viewport-height: calc(var(--app-layout-content-height) - var(--app-layout-sticky-area-height));

$guest-header-height: 57px;
$guest-header-menu-push-tablet: 330px;
$guest-header-menu-push-phone: 256px;

$overlay-z-index: 1003;
// We should have the same z-index with $overlay-z-index,
// because it is possible to have fullscreen in overlay (dialogs) and overlay in fullscreen(context menu, dropdowns and etc.)
// https://workato.atlassian.net/browse/UI-1058
// https://workato.atlassian.net/browse/UI-991
$code-editor-full-screen-z-index: 1003;
$dnd-z-index: 8000;

$fullscreen-opening-duration: 0.35s;
$fullscreen-page-horizontal-margin: 12px;

$widget-copyright-height: 20px;
$widget-copyright-space: 8px;

$shimmer-animation: shimmer 4s cubic-bezier(0.45, 0.05, 0.35, 0.95) infinite forwards;
